<div class="privacyPolicy">
    <h1>{{ "privacyPolicy.h1" | translate }}</h1>
    <p>{{ "privacyPolicy.responsiblePerson" | translate }}
    </p>
    <p>Davide Religioso</p>

    <h2>{{ "privacyPolicy.yourDataSubjectRights.h2" | translate }}</h2>
    <p>{{ "privacyPolicy.yourDataSubjectRights.explain" | translate }}</p>
    <ul>
        <li>{{ "privacyPolicy.yourDataSubjectRights.one" | translate }}</li>
        <li>{{ "privacyPolicy.yourDataSubjectRights.two" | translate }}</li>
        <li>{{ "privacyPolicy.yourDataSubjectRights.three" | translate }}</li>
        <li>{{ "privacyPolicy.yourDataSubjectRights.four" | translate }}</li>
        <li>{{ "privacyPolicy.yourDataSubjectRights.five" | translate }}</li>
        <li>{{ "privacyPolicy.yourDataSubjectRights.six" | translate }}</li>
    </ul>
    <p>{{ "privacyPolicy.yourDataSubjectRights.ifYouHaveGivenUs..." | translate }}</p>
    <p>{{ "privacyPolicy.yourDataSubjectRights.youCanLodgeAComplaint..." | translate }}</p>
    <p>{{ "privacyPolicy.yourDataSubjectRights.aListOfSupervisoryAuthorities..." | translate }} <a
            href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank"
            rel="noopener nofollow">www.bfdi.bund.de</a>.</p>
    <p></p>

    <h2>{{ "privacyPolicy.contactForm.h2" | translate }}</h2>

    <h3>{{ "privacyPolicy.contactForm.typeAndPurposeOfProcessing.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.contactForm.typeAndPurposeOfProcessing.text" | translate }}</p>

    <h3>{{ "privacyPolicy.contactForm.legalBasis.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.contactForm.legalBasis.text.one" | translate }}</p>
    <p>{{ "privacyPolicy.contactForm.legalBasis.text.two" | translate }}</p>
    <p>{{ "privacyPolicy.contactForm.legalBasis.text.three" | translate }}</p>

    <h3>{{ "privacyPolicy.contactForm.recipient.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.contactForm.recipient.text" | translate }}</p>
    <p></p>

    <h3>{{ "privacyPolicy.contactForm.thirdCountryTransfer.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.contactForm.thirdCountryTransfer.text.one" | translate }}</p>
    <p>{{ "privacyPolicy.contactForm.thirdCountryTransfer.text.two" | translate }}</p>
    <p>{{ "privacyPolicy.contactForm.thirdCountryTransfer.text.three" | translate }}</p>
    <p></p>

    <h3>{{ "privacyPolicy.contactForm.storageDuration.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.contactForm.storageDuration.text.one" | translate }}</p>
    <p>{{ "privacyPolicy.contactForm.storageDuration.text.two" | translate }}</p>

    <h3>{{ "privacyPolicy.contactForm.provisionMandatoryOrRequired.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.contactForm.provisionMandatoryOrRequired.text" | translate }}</p>
    <p></p>

    <h2>{{ "privacyPolicy.useOfScriptLibraries.h2" | translate }}</h2>
    <p>{{ "privacyPolicy.useOfScriptLibraries.inOrderToDisplayOurContent..." | translate }}</p>
    <p>{{ "privacyPolicy.useOfScriptLibraries.furtherInformationOn..." | translate }} <a
            href="https://developers.google.com/fonts/faq" rel="noopener nofollow"
            target="_blank">developers.google.com/fonts</a> {{ "privacyPolicy.useOfScriptLibraries.andInThe..." |
        translate }} <a href="https://www.google.com/policies/privacy/" rel="noopener nofollow"
            target="_blank">www.google.com/policies/privacy</a>.</p>
    <p></p>

    <h2>{{ "privacyPolicy.sslEncryption.h2" | translate }}</h2>
    <p>{{ "privacyPolicy.sslEncryption.text" | translate }}</p>
    <p></p>
    <hr>

    <h2>{{ "privacyPolicy.informationAboutYourRightToObjectInAccordance.h2" | translate }}</h2>
    <h3>{{ "privacyPolicy.informationAboutYourRightToObjectInAccordance.individualRightToObject.h3" | translate }}</h3>
    <p>{{ "privacyPolicy.informationAboutYourRightToObjectInAccordance.individualRightToObject.text.one" | translate }}
    </p>
    <p>{{ "privacyPolicy.informationAboutYourRightToObjectInAccordance.individualRightToObject.text.two" | translate }}
    </p>

    <h3>{{ "privacyPolicy.informationAboutYourRightToObjectInAccordance.recipientOfAnObjection.h3" | translate }}</h3>
    <p>Davide Religioso
        <hr>

    <h2>{{ "privacyPolicy.changesToOurPrivacyPolicy.h2" | translate }}</h2>
    <p>{{ "privacyPolicy.changesToOurPrivacyPolicy.text" | translate }}</p>

    <h2>{{ "privacyPolicy.questionsDataProtectionOfficer.h2" | translate }}</h2>
    <p>{{ "privacyPolicy.questionsDataProtectionOfficer.text" | translate }}</p>
    <p></p>
    <p><em>{{ "privacyPolicy.questionsDataProtectionOfficer.providedBy" | translate }}
            <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">
                {{ "privacyPolicy.questionsDataProtectionOfficer.providedLink" | translate }}</a>
            {{ "privacyPolicy.questionsDataProtectionOfficer.providedEnd" | translate }}
    </em></p>
</div>