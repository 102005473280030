<h2>{{ "aboutMe" | translate }}</h2>

<div class="aboutMeContent">
    <div class="textAndBtn">
        <span>{{ "aboutMeText" | translate }}</span>

        <a href="#contact">Let's talk</a>
    </div>

    <div class="aboutMeImage">
        <img src="assets/img/foto.jpg">
        <div class="imageBorder"></div>
    </div>
</div>