<div class="dropdownContainer" id="dropdownContainer">
    <div class="changeLanguageBtn" id="changeLanguageBtn"
        style="background: #FFFCF3 url('assets/img/dropdownArrow.png') no-repeat right 10px center;"
        (click)="this.portfolioService.clickFunctionChangeLanguageDropdown()">
        {{ selectedLanguage }}
    </div>

    <div class="languageList" id="languageList">
        <span *ngFor="let language of languages" (click)="selectLanguage(language)">{{ language }}</span>
    </div>
</div>