<footer>
    <div class="links">
        <a href="https://github.com/GDR1909" target="_blank">
            <img src="assets/img/githubIcon.png">
        </a>

        <a href="#contact">
            <img src="assets/img/emailIcon.png">
        </a>

        <a href="https://www.linkedin.com/in/davide-religioso-581734309/" target="_blank">
            <img src="assets/img/linkedinIcon.png">
        </a>
    </div>

    <img src="assets/img/logoWhite.png" class="logo">

    <div class="copyright">
        <img src="assets/img/copyright.png">
        <span>Davide Religioso 2024</span>
    </div>

    <a routerLink="/legal-notice" routerLinkActive="active" class="legalNotice">{{ "legalNoticeLink" | translate }}</a>
</footer>