<app-landingPage id="landingPage" (click)="closeDropdown()"></app-landingPage>

<app-about-me id="aboutMe" (click)="closeDropdown()"></app-about-me>

<app-arrow-right (click)="closeDropdown()"></app-arrow-right>

<app-my-skills id="mySkills" (click)="closeDropdown()"></app-my-skills>

<app-arrow-left (click)="closeDropdown()"></app-arrow-left>

<app-portfolio id="portfolio" (click)="closeDropdown()"></app-portfolio>

<app-arrow-right (click)="closeDropdown()"></app-arrow-right>

<app-contact id="contact" style="background-image: url('assets/img/contactBackground.png')" (click)="closeDropdown()"></app-contact>

<app-sent-message id="sentMessage"></app-sent-message>