<div class="contact">
    <h2>Say Hi!</h2>

    <div class="h3andH4">
        <h3>{{ "contactH3" | translate }}</h3>
        <h4>{{ "contactH4" | translate }}</h4>
    </div>

    <a href="#landingPage">
        <img src="assets/img/scrollToTopArrow.png">
    </a>

    <app-contact-form></app-contact-form>
</div>