<h1>{{ "frontendDeveloper" | translate }}</h1>

<div class="borderlineAndName">
    <div class="borderline"></div>
    <h2>Davide Religioso</h2>
</div>

<a href="#contact" class="sendAMessageButton">Send a message</a>

<a href="#contact" class="email">mail&#64;davide-religioso.com</a>

<div class="scrollDownDiv">
    <img src="assets/img/scrollDownArrow.png">
    <a href="#aboutMe" class="scrollDownText">{{ "scrollDown" | translate }}</a>
</div>