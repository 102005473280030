<div class="legalNotice">
    <h1>{{ "legalNotice.h1" | translate }}</h1>
    <br>
    <br>
    <h2>{{ "legalNotice.InformationAccordingTo§5TMG.h2" | translate }}</h2>
    <p>Davide Religioso<br>{{ "legalNotice.InformationAccordingTo§5TMG.frontendDeveloper" | translate }}</p>
    <br>
    <h3>{{ "legalNotice.postalAdress" | translate }}</h3>
    <p>Paprikastraße 58<br>70619 Stuttgart</p>
    <br>
    <h3>{{ "legalNotice.contact.h3" | translate }}</h3>
    <p>{{ "legalNotice.contact.phone" | translate }} 0160 2043995<br>{{ "legalNotice.contact.email" | translate }} mail&#64;davide-religioso.com</p>
    <br>
    <br>
    <h2>{{ "legalNotice.InformationAccordingTo§36VSBG.h2" | translate }}</h2>
    <p>{{ "legalNotice.InformationAccordingTo§36VSBG.one" | translate }}</p>
    <br>
    <p><b>{{ "legalNotice.InformationAccordingTo§36VSBG.two" | translate }}</b></p>
    <br>
    <br>
    <p><em>{{ "legalNotice.InformationAccordingTo§36VSBG.three.firstPart" | translate }} <a href="https://www.activemind.de/datenschutz/generatoren/impressum/">
        {{ "legalNotice.InformationAccordingTo§36VSBG.three.aTag" | translate }}</a> 
        {{ "legalNotice.InformationAccordingTo§36VSBG.three.secondPart" | translate }}
        </em>
    </p>
</div>